/**
 * Teasers
 */

 $border-width: 0;

.teasers {
    @include list-unstyled();
    @include make-row();
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
}

.teaser {
    display: flex;
    margin: ($grid-gutter-width / 2) 0;
    position: relative;
    width: 100%;
    @include make-col-ready();
    @extend .col-sm-6;
    @extend .col-lg-3;
    text-align: left;

    h3 {
        margin: 0.5rem 0;
        font-size: $font-size-base;
        @include media-breakpoint-up(md) {
            font-size: $font-size-lg;
        }
    }
    p {
        margin: 0;
    }
    .btn {
        @extend .btn-block;
    }
    .card {
        &-img {
            @include ratio(1 1);
            background: $secondary;
            &-overlay {
                color: $primary;
                top: auto;
                padding: $card-spacer-x;
                h3 {
                    color: $white;
                    position: relative;
                    font-size: $h3-font-size;
                    // font-family: $font-heading;
                    text-transform: uppercase;
                }
                p {
                    @include font-small();
                    color: $light-text;
                }
            }
        }
    }
    &-nodesc {
        .card {
            &-img {
                &-overlay {
                    @include linear-gradient(rgba($dark, 0), rgba($dark, 0.8));
                }
            }
        }
    }

    &-desc {
        @include ratio(1 1);

        p,
        h3 {
            color: $secondary;
            text-transform: uppercase;
            // font-family: $font-heading;
            font-size: $h3-font-size;
        }

        &.teaser-home .card:not(:hover) {
            border-color: $gray-lighter;
        }

        // &:nth-child(odd) {
        //     .card {
        //         border: .5rem solid $primary;
        //         border-image: repeating-linear-gradient( -45deg, $secondary, $secondary 1%, $white 1%, $white 2%) 7.5;
        //         @include transition($transition-color);
        //         &:hover {
        //             border-image: repeating-linear-gradient( -45deg, $white, $white 1%, $secondary 1%, $secondary 2%) 7.5;
        //         }
        //     }
        // }
        // &:nth-child(even) {
        //     .card {
        //         border: .5rem solid $primary;
        //         border-image: repeating-linear-gradient( -45deg, $secondary, $secondary 1%, $white 1%, $white 2%) 7.5;
        //         @include transition($transition-color);
        //         &:hover {
        //             border-image: repeating-linear-gradient( -45deg, $white, $white 1%, $secondary 1%, $secondary 2%) 7.5;
        //         }
        //     }
        // }
    }

    &-title {
        margin: 0;
        &-list {
            flex: none;
            display: block;
            margin: 1rem 0;
            @include media-breakpoint-up(lg) {
                column-count: 2;
            }
        }
        a {
            display: block;
            text-decoration: none;
            width: 100%;
            @include transition($transition-base);
            &:hover {
                @include hoverboard();
                box-shadow: none;
                h3 {
                    color: $primary;
                    &:before {
                        background: $primary;
                    }
                }
            }
        }
        h3 {
            display: inline-block;
            line-height: 1.5rem;
            padding: 0 1rem;
            position: relative;
            @include transition($transition-base);
            @include media-breakpoint-up(md) {
                line-height: 2rem;
                padding: 0 1.5rem;
            }
            &:before {
                background: $gray-light;
                content: "";
                position: absolute;
                height: 0.5rem;
                width: 0.5rem;
                top: 0.5rem;
                left: 0;
                bottom: 0;
                @include transition($transition-base);
                @include media-breakpoint-up(md) {
                    height: 0.75rem;
                    width: 0.75rem;
                }
            }
        }
    }

    &.teaser-title {
        @extend .col-12;
        @extend .col-lg-12;
        padding: 0;
    }

    &-sm {
        h3 {
            margin: 0;
            float: left;
            line-height: 2rem;
        }
        .card {
            &-body {
                padding: 1rem;
            }
        }
        .btn {
            @extend .btn-sm;
            width: auto;
            float: right;
            top: 0;
        }
    }
}

.column {
    .teaser {
        @extend .col-sm-12;
        @extend .col-lg-6;
    }
}

.text-center {
    .teasers {
        @extend .justify-content-center;
    }
}

.present {
    @include ratio(1 1);
    .card {
        color: $white;
        background-color: $dark;
        background-image: url("../img/teaser-background-image.jpg");
        background-size: cover;
        .btn-facebook {
            border-color: $white !important;
            color: $white;
            @include transition($transition-base);
            &:hover {
                background: $white;
                color: $dark;
            }
        }
    }
}

.robarov-sponsor {
    @include ratio(1 1);
    .card {
        background: $robared url("../img/sponsor-robarov.png") 50% 50% no-repeat;
        background-size: contain;
        color: $white;
        p {
            text-align: center;
            font-family: $font-family-sans-serif;
            text-transform: none;
            font-size: $font-size-base;
            padding-top: 1.5rem;
            font-weight: $font-weight-bold;
        }
    }
}

.sponsored-teaser {
    p {
        text-align: center;
        font-size: $font-size-lg;
        text-transform: none;
        // font-family: $font-heading;
        color: $dark;
    }
}

.teaser-home {
    @include ratio(1 1);
    p, h3 {
        text-align: left;
        text-transform: uppercase;
        font-size: $h3-font-size;
        // letter-spacing: -0.025em;
    }
    .card {
        border: $border-width solid $gray-dark;
        &:hover  {
            border-color: $primary;
        }
        &.primary {
            background: $primary;
            // border: $border-width solid $gray-lighter !important;
            &:hover  {
                border-color: $gray-dark !important;
                background: lighten($primary, .25)
            }
            .btn {
                // border-color: $secondary;
                // @extend .btn-outline-secondary;
                &:hover {
                    // @extend .btn-outline-secondary;
                }
            }
        }
        &.light-wisteria {
            background: $light-wisteria;
            &:hover  {
                background: lighten($light-wisteria, .25)
            }
        }
        &.orange {
          background: $orange;
          &:hover  {
              background: lighten($orange, .25)
          }
      }
        &.pink {
            background: $pink;
            &:hover  {
                background: lighten($pink, .15);
            }
        }
    }
}
