 /**
 * Page header
 */

.page-header {
    background: $dark;
    position: relative;
    padding-bottom: 2rem;
    padding-top: 8rem;
    overflow: hidden;
    margin-top: -8rem !important;
    text-align: center;
    @include media-breakpoint-up(md) {
        padding-bottom: 4rem;
        padding-top: 14rem;
    }
    p {
        text-align: center;
        font-family: $headings-font-family;
        color: $white;
        font-size: $font-size-base;
        @include media-breakpoint-up(md) {
            font-size: $font-size-lg;
        }
    }

    .richtext {
        ul {
            li {
                padding-left: 0;
                &:before {
                    display: none;
                }
            }
        }
    }

    .container {
        position: relative;
        z-index: 2;
    }
    .lazyloaded {
        opacity: .3;
    }

    h1 {
        color: $white;
        display: inline-block;
        position: relative;
    }
}