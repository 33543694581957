/**
 * Downloads
 */

.download {
    color: $body-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-left: 3.5rem;
    &:hover, &:focus {
        color: $primary;
    }
    span {
        position: absolute;
        left: $list-group-item-padding-y;
    }
    small {
        color: $muted;
        display: block;
        text-align: right;
        white-space: nowrap;
        margin-left: 1rem;
    }
    &-items {
        .list-group {
            margin-bottom: 4rem;
        }
    }
}