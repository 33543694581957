/**
 * Cards
 */

.card {
    &-header {
        background: transparent;
        border: 0;
    }
    &-footer {
        background: transparent;
        border: 0;
        padding-top: 0;
/*        margin-left: auto;
        margin-right: auto;*/
    }
    &-img {
        background: $card-cap-bg;
        @include ratio(4 3);
        &-left {
            @include media-breakpoint-up(lg) {
                position: absolute;
                top: 0;
                bottom: 0;
                width: 40%;
                border-radius: 0;
                padding-right: 0;
            }
        }
        &-top {
            @include ratio(2 1);
        }
    }
    .nav {
        padding: $card-spacer-y 0;
        &-link {
            color: $gray;
            padding-left: $card-spacer-x;
            padding-right: $card-spacer-x;
            &:hover, &.active {
                color: $primary;
            }
        }
    }
}

a.card, .teaser div.card {
    border: 0;
    color: inherit;
    cursor: pointer;
    text-decoration: none;
    @include transition($transition-base);
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    width: 100%;
    &:hover {
        @include hoverboard();
    }
}

.teaser-home a.card, .sponsored-teaser a.card, .sponsored-teaser div.card .teaser-home div.card {
    &:hover {
        -webkit-transform: translateY(0rem);
         transform: translateY(0rem);
        box-shadow: none;    
    }
}

.teaser div.card {
    cursor: default;
}

.bg-light {
    .card {
        background: $white;
    }
}
.bg-dark {
    .card {
        background: $white;
    }
}
