/**
 * artists
 */

.artists-overview {
    @extend .col-md-12;

    .teaser {
        @extend .col-md-6;
        @extend .col-lg-4;
    }
    .social-nav {
        @include list-unstyled();
        margin: -.25rem 0 1.75rem;
        @include media-breakpoint-up(sm) {
            column-count: 2 !important;
        }
        .nav-link {
            padding: .25rem 0;
            font-size: $h4-font-size;
            font-family: $font-heading;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            .icon {
                margin-right: 1rem;
                font-size: 2rem;
            }
            &:hover {
                color: $secondary;
            }
        }
    }
}

.filter {

    @extend .col-md-4;
    @extend .col-lg-3;

    +.artists {
        @extend .col-md-8;
        @extend .col-lg-9;
    }

    &-toggle {
        @include media-breakpoint-up(md) {
            display: none;
        }
        &:hover {
            -webkit-transform: translateY(0px);
            transform: translateY(0px);
        }
        i, svg {
            @include transition(transform .4s);
            transform: rotate(180deg);
        }
        &.collapsed {
            i, svg {
                transform: rotate(0);
            }
        }
    }
    &-wrap {
        margin: 1rem 0;
        &.collapse {
            @include media-breakpoint-up(md) {
                display: block;
                height: auto;
            }
        }
    }

    fieldset {
        padding: .5rem 0;
    }

    hr {
        margin-top: 0;
        width: 2rem;
    }

    label {
        cursor: pointer;
        @include transition($transition-color);
        &:hover {
            color: $primary;
        }
    }
    h4 {
        @include font-small();
        margin-bottom: .5rem;
    }
    h3{
        text-transform: none;
        font-size: $font-size-lg;
        @include media-breakpoint-up(md) {
            font-size: $h4-font-size;
        }
        // &:before{
        //     border-left: solid $primary 2px;
        //     content: "";
        //     left: -1.5rem;
        //     position: relative;
        // }
    }
    h2 {
        font-size: $h4-font-size;
    }

}

.artists {
    &-teaser {
        &.teaser {
            @extend .col-lg-3;
        }
        .card {
            &-img-overlay {
                h3 {
                    @include font-base();
                    margin: 0 0 .25rem;
                    // letter-spacing: 0;
                    &:before {
                        width: 2rem;
                    }
                }
            }
        }
    }
    figure {
        @include ratio(1 1);
        &:before {
            display: none;
        }
        img {
            // max-height: 40rem;
            width: auto;
            position: relative;
        }
    }
}

.section-artists {
    .artists {
        @extend .col-12;
        .teaser {
            @extend .col-md-6;
            @extend .col-lg-3;
        }
    }

    .filter + .artists > .filter-items {
        .teaser {
            @extend .col-lg-4;
        }
    }
}

.year {
    &-teaser {
        .card-img {
            @include ratio(7 10);
        }
    }
    &-filter {
        margin-top: $spacer;
        li {
            &:not(:last-child):after {
                content: ' // ';
                color: $gray;
                font-size: $font-size-lg;
            }
        }
    }
}

.year-filter, .az-filter {
    padding-left: 0;
    text-align: center;
    width: 100%;
    li {
        display: inline-block;
        a {
          color: $light;
          font-size: $font-size-lg;
          text-decoration: none;
          padding: 0 $spacer * .2;
          &:hover, &:focus {
            color: $primary;
          }
        }
    }
}

.section-year {
    .poster {
        @extend .col-lg-6;
        @include media-breakpoint-down(md) {
            margin-bottom: $spacer * 2;
        }
    }
    .line-up {
        @extend .col-lg-6;
        ul {
            padding-left: 0;
            li {
                display: inline-block;
                a {
                    text-decoration: none;
                    color: $gray-dark;
                    &:hover, &:focus {
                        color: $primary;
                    }
                }
                &:not(:last-child):after {
                    content: '//';
                    padding: 0 ($spacer * .5);
                    color: $gray-light;
                }
            }
        }
    }
}

.section-az {
    .artists {
        width: 100%;
    }
}

.section-filter {
    padding-bottom: 0;
}

.social-share {
    h4 {
        display: inline-block;
        vertical-align: middle;
        margin: $nav-link-padding-y $nav-link-padding-y $nav-link-padding-y 0;
    }
    a {
        color: $headings-color;
        &:hover {
            color: $primary;
        }
    }
    .icon {
        font-size: 1.5rem;
    }
}

.artists-card {
    // margin: ($grid-gutter-width / 2) 0;
    padding: $card-spacer-y 0;
    background-color: $white;
    border: .5rem solid $primary;
    border-image: repeating-linear-gradient( -45deg, $white, $white 1%, $secondary 1%, $secondary 2%) 7.5;
    margin-bottom: 2rem;
    p {
        color: $primary;
        font-size: $h3-font-size;
        font-family: $font-heading;
    }
}

.history {
    ul {
        padding-left: 0;
        list-style: none;
        li {
            display: inline-block;
            &:not(:last-child):after {
                content: ' // ';
                color: $gray;
                padding: 0 $spacer * .5;
            }
            a {
                color: $secondary;
                text-decoration: none;
                &:hover, &:focus {
                    color: $primary;
                }
            }
        }
    }
}

// .filter + .artists > .filter-items {
//     .teaser {
//         @extend .col-lg-4;
//     }
// }

// * {
//     border: dotted pink 1px;
// }

.artists-carousel.owl-carousel {
    margin-right: 0;
    margin-left: 0;
    overflow: hidden;
    .teaser.artists-teaser {
        padding-left: 0;
        padding-right: 0;
        max-width: 100%;
    }
}

.fade-to-transparent {
    position: relative;
    &::before, &::after {
        position: absolute;
        top: 0;
        height: 100%;
        width: 2rem;
        content: '';
        z-index: 99;
    }
    &::before {
        left: 0;
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    }
    &::after {
        right: 0;
        background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);

    }
}
