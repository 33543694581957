/**
 * News
 */


.news-latest {
    color: $secondary;
    h3 {
        // font-family: $font-heading;
        text-transform: uppercase;
        color: $secondary;
        @include media-breakpoint-up(sm){
            padding-right: 4rem;
        }

    }
    .row {
        margin: 0 !important;
    }
    .news-img {
        border: .5rem solid $secondary;
        border-bottom: none;
        position: relative;
        @include ratio(5 3);
        @include media-breakpoint-up(md) {
            border-color: $primary;
            border: none;
        }
    }
    .news-date {
        background: $primary;
        color: $white;
        font-size: $h6-font-size;
        // letter-spacing: -.025em;
        // font-family: $font-heading;
        text-transform: uppercase;
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        display: inline-block;
        padding: .25rem 1rem;
        @include media-breakpoint-up(sm) {
            bottom: 2rem;
            right: 3rem;
            padding: .5rem 1rem;
        }
    }
    .news-summary {
        background: $white;
        border: .5rem solid $gray-lighter;
        // border-top: none;
        padding: 2rem;
        position: relative;
        // @include media-breakpoint-up(md) {
        //     border: .5rem solid $secondary;
        //     &:before {
        //         background: url(../img/corner2020.svg) 0 0 no-repeat;
        //         background-size: cover;
        //         content: "";
        //         position: absolute;
        //         top: 0;
        //         right: 0;
        //         height: 5rem;
        //         width: 5rem;
        //         margin: -.5rem -.5rem 0 0;
        //     }
        // }
        p {
            color: $secondary;
        }
    }
    .btn {
        display: block;
        @include media-breakpoint-up(lg) {
            display: inline-block;
            margin: 0;
            vertical-align: top;
            + .btn {
                float: right;
            }
        }
    }
}

.post {
    border-bottom: 1px solid $light;
    margin: 0 auto $spacer;
    max-width: 44rem;
    padding-bottom: $spacer;
    @include media-breakpoint-up(md) {
        padding-bottom: $spacer * 2;
        margin-bottom: $spacer * 2;
    }
    &:last-of-type {
        border: 0;
    }
    a {
        color: $body-color;
        display: block;
        text-decoration: none;
        &:hover {
            figure {
                @include hoverboard();
            }
        }
    }
    .btn {
        margin-bottom: .75rem;
    }
    &-meta {
        @include list-unstyled();
        position: relative;
        li {
            @include font-small();
            color: $gray;
                padding-left: 2rem;
                &:before {
                    background: url(../img/calendar.svg) 0 0 no-repeat;
                    background-size: cover;
                    content: "";
                    position: absolute;
                    left: 0;
                    top:0;
                    bottom: 0;
                    margin: auto 0;
                    height: 1rem;
                    width: 1rem;
                }
        }
    }
    &-intro {
        padding-bottom: 0;
        .post-meta {
            margin-top: -1rem;
        }
    }
    &-image {
        padding: 0 0 2rem;
        img {
            max-height: 25rem;
        }
    }
}
