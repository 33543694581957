/**
 * Type
 */

@font-face {
    font-family: "Aptos";
    src: url("../fonts/aptos-regular.woff2") format("woff2"), url("../fonts/aptos-regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0-10FFFF;
}

@font-face {
    font-family: "Aptos";
    src: url("../fonts/aptos-extrabold.woff2") format("woff2"), url("../fonts/aptos-extrabold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0-10FFFF;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    padding-top: 7.5rem;
    font-size: $font-size-sm;
    // letter-spacing: -0.015em;
    @include media-breakpoint-up(md) {
        font-size: $font-size-base;
        padding-top: 8rem;
    }
}

h1,
h2 {
    font-family: $font-heading;
    text-transform: uppercase;
}

h3, h4, h5 {
    font-family: $font-family-base;
    text-transform: uppercase;
}

p + h2,
p + h3,
ul + h2,
ul + h3,
ol + h2,
ol + h3,
.table-responsive + h2,
.table-responsive + h3 {
    padding-top: 2rem;
}

h1 {
    font-size: $font-size-base * 2;
    @include media-breakpoint-up(md) {
        font-size: $h1-font-size;
    }
    &:only-child {
        margin: 0;
    }
}

h1 > .strong {
    font-weight: $font-weight-base;
}

h2 {
    font-size: $h3-font-size;
    @include media-breakpoint-up(md) {
        font-size: $h2-font-size;
    }
}

h3 {
    color: $secondary;
    font-size: $h4-font-size;
    @include media-breakpoint-up(md) {
        font-size: $h3-font-size;
    }
}

h4 {
    font-size: $h5-font-size;
    @include media-breakpoint-up(md) {
        font-size: $h4-font-size;
    }
}

h5 {
    font-size: $h6-font-size;
    @include media-breakpoint-up(md) {
        font-size: $h5-font-size;
    }
}

.content {
    p,
    ul:not([class]) {
        line-height: 1.75;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

hr {
    width: 4rem;
    display: inline-block;
    &.thin {
        border-top-color: $light;
        border-top-width: 1px;
        margin-left: -4rem;
        width: 8rem;
        left: 50%;
        position: absolute;
        @include media-breakpoint-up(md) {
            margin-top: 2rem;
        }
    }
}

b,
strong {
    font-weight: $font-weight-bold;
}

dt {
    color: $dark;
}

dd {
    margin: 0;
}

h5 {
    margin-bottom: 0;
    line-height: $line-height-base;
}

a {
    @include transition($transition-color);
    font-weight: $font-weight-base;
}

p a,
ul:not([class]) a {
    text-decoration: underline;
    // &:hover, &:focus {
    //     text-decoration: none;
    //     color: $secondary;
    // }
}

.richtext {
    ul:not(.post-meta) {
        @include list-unstyled();
        li {
            position: relative;
            padding-left: 2rem;
            &:before {
                // background: url(../img/sound.svg) 0 0 no-repeat;
                // background-size: cover;
                background-color: $primary;
                -webkit-mask-image: url(../img/sound.svg);
                mask-image: url(../img/sound.svg);
                content: "";
                position: absolute;
                left: 0;
                top: 5px;
                bottom: 0;
                // margin: auto 0;
                height: 1rem;
                width: 1rem;
                @include media-breakpoint-up(md) {
                    top: 7px;
                }
            }
        }
    }
}

a.tel {
    color: inherit;
    text-decoration: none;
}
