/**
 * Buttons
 */

.btn {
 	font-weight: $font-weight-bold;
 	font-size: $font-size-sm;
    text-transform: uppercase;
    vertical-align: top;
    white-space: normal;
    margin-bottom: 1rem;
    // border: 2px solid $secondary;
    // letter-spacing: 1.4px;
    &:last-child {
        margin-bottom: 0;
    }
    @include transition($transition-base);

    // &-primary {
    //     text-shadow: 0 1px 0 rgba(black,.08);
    //     &:hover, &:focus, &:active, &:active:focus {
    //         background: lighten($primary, 10);
    //         border-color: lighten($primary, 10);
    //         /*box-shadow: 0 2px 8px rgba($primary, 0.32);*/
    //     }
    // }
    &-facebook {
        &:hover, &:focus, &:active, &:active:focus {
            background: $primary;
            border-color: $primary;
            color: $white;
        }
    }
}

.button {
    .btn {
        @include media-breakpoint-only(xs) {
            display: block;
            width: 100%;
        }
        + .btn {
            @include media-breakpoint-up(sm) {
                margin-left: 1rem;
            }
        }
    }
}

.text-center, .section-1column {
    .button {
        margin-left: auto;
        margin-right: auto;
    }
}

.content {
    +.button {
        margin-bottom: $spacer * 2;
    }
}

.sidebar {
    .btn {
        + .btn {
            @include media-breakpoint-up(sm) {
                margin-left: 0rem;
            }
        }
    }
}


.btn-lg {
	font-size: $font-size-base;
}


.col-2-3-1-3 {
    .column + .column {
        .btn {
            margin: 0;
            display: block;
            width: 100%;
            + .btn {
                margin-top: 1rem;
            }
        }
    }
}


a {
    &:hover {
        .btn {
            @extend :hover;
        }
    }
}

.cc-btn {
    &:hover, &:focus {
        text-decoration: none;
    }
}

.navbar {
  @include media-breakpoint-up(lg) {
      .nav-item.nav-accent {
        background-color: $primary;
        transition: all .5s ease-in-out;

        padding: 0 .75rem;
        margin-right: 0;

        // Remove the after effect on hover
        > .nav-link:after {
            display: none;
        }

        &:hover {
          background-color: $pink;
        }
      }
  }
}
