/**
 * Locations
 */

.location {
    .content {
        margin-left: 0;
        margin-right: 0;
        text-align: left;
    }
    &-image {
        @extend .col-md-6;
        @extend .col-lg-5;
    }
    &-info {
        @extend .col-md-6;
        @extend .col-lg-7;
        @extend .col-xl-6;
        @extend .offset-xl-1;
        dl {
            @include media-breakpoint-up(lg) {
                width: 50%;
                float: left;
                margin: 0;
            }
        }
        ul {
            @include list-unstyled();
            em {
                display: inline-block;
                width: 6rem;
                font-style: normal;
            }
        }
    }
}