/**
 * Faq
 */


.faq {
    &-list {
        margin-bottom: 4rem;
        .card {
            background: $white;
            &-header {
                color: $dark;
                display: block;
                text-decoration: none;
                &:hover {
                    color: $primary;
                    h3 {
                        color: $primary;
                    }
                }
            }
            &-body {
                padding-top: 0;
            }
        }
        h3 {
            @include transition($transition-base);
            font-size: $font-size-base;
            line-height: $line-height-base;
            margin: 0;
            padding-right: 1rem;
            @include media-breakpoint-up(md) {
                font-size: $font-size-lg;
            }
        }
        p:last-child {
            margin-bottom: 0;
        }
        svg {
            @include transition($transition-base);
            position: absolute;
            top: 1.5rem;
            right: 1rem;
            font-size: inherit;
            line-height: inheit;
        }
    }

    &-teaser {
        @extend .col-sm-6;
        @extend .col-lg-3;
        .card {
            // background: $dark;
            &:after {
                content: '?';
                display: inline-block;
                position: absolute;
                font-size: 6rem;
                line-height: 4rem;
                font-weight: $font-weight-bold;
                color: rgba($black, .04);
                z-index: 0;
                top: 1.5rem;
                right: .25rem;
                // margin-top: -1.5rem;
            }
            &-body {
                position: relative;
                z-index: 1;
            }
        }
    }

}

.faq-list .collapsed svg {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}


.target {
    display: block;
    height: 10rem;
    position: relative;
    margin-top: -10rem;
    z-index: -1;
}

.accordion {
    .card:only-child {
        border-bottom: 1px solid $card-border-color;
        border-radius: $border-radius;
    }
}
