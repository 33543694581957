/**
 * Call to action
 */

.cta {
	color: $gray-dark;
	background-color: $white;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	padding-left: $grid-gutter-width;
	padding-right: $grid-gutter-width;
	padding-top: $spacer * 2;
	padding-bottom: $spacer * 2;
    border: .5rem solid $gray-lighter;
    // border-image: $border-attention;
    // &:before {
    //     background: url(../img/corner2020.svg) 0 0 no-repeat;
    //     background-size: cover;
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     height: 5rem;
    //     width: 5rem;
    //     margin: -.5rem -.5rem 0 0;
    // }
	@include media-breakpoint-up(md) {
		padding-top: $spacer * 4;
		padding-bottom: $spacer * 4;
    }
	&.hasbg {
		background: $dark;
	}
	h2, h3, h4 {
		margin-top: 1rem;
		color: $dark;
	}
	p {
		max-width: 42rem;
		margin-right: auto;
		margin-left: auto;
		color: $dark !important;
	}
	p, li {
		font-size: $font-size-base;
		margin-bottom: 1rem;
		@include media-breakpoint-up(md) {
			font-size: $font-size-lg;
		}
		a {
			color: $light-text;
			&:hover {
				color: $primary;
			}
		}
	}
	.btn-primary {
		border-color: $primary;
		background: $primary;
		color: $white;
		text-shadow: none;
		&:hover, &:focus, &:active, &:active:focus {
			border-color: $primary;
			background: $primary;
		    box-shadow: 0 4px 16px rgba($primary, 0.32);
		}
	}
	.btn-outline-primary {
		background: transparent;
		border-color: $dark;
		color: $dark;
		&:hover, &:focus, &:active, &:active:focus {
			color: white;
			background: $dark;
			border-color: $dark;
		}
	}
	&-secondary {
		background-color: $secondary;
		background-image: linear-gradient(45deg, $secondary, $dark);

		.btn-primary {
			color: $secondary;
		}

		.btn-primary, .btn-outline-primary {
			&:hover, &:focus, &:active, &:active:focus {
				color: $secondary;
			}
		}
	}
	.button {
		margin: 0 auto;
	}
}
