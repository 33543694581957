/**
 * Components: Slideshow
 */

.slideshow {

}

.slides {
    overflow: hidden;
}

.slide {
    background: $light;
    float: left;
    &-img {
        @include ratio(16 9);
    }
}


// Lightslider
.lSAction {
    > a {
        background-image: url(../img/controls.svg);
    }
    .lSPrev {
        left: 1rem;
    }
    .lSNext {
        right: 1rem;
    }
}
.lSSlideOuter .lSPager.lSpg>li a {
    background-color: $muted;
}

.lSSlideOuter .lSPager.lSpg>li.active a {
    background-color: $primary;
}