// ======
// Mixins
// ======


// Ratio
@mixin ratio($ratio: 1 1, $selector: "&:before") {
    $selector: unquote($selector);
    position: relative;
    overflow: hidden;
    #{$selector} {
        content: '';
        display: block;
        padding-bottom: percentage(nth($ratio, 2) / nth($ratio, 1));
        height: 0;
    }
    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}


// Clearfix
@mixin clearfix {
  &::after {
    clear: both;
    content: "";
    display: block;
  }
}


// Fonts
@mixin font-base() {
    font-size: $font-size-base;
    line-height: $line-height-base;
}

@mixin font-small() {
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
}

// Linear gradient
@mixin linear-gradient ($firstColor, $secondColor) {
    background: $firstColor;
    background: linear-gradient(to bottom, $firstColor 0%, $secondColor 100%);
    background: -moz-linear-gradient(top, $firstColor 0%, $secondColor 100%);
    background: -ms-linear-gradient(top, $firstColor 0%, $secondColor 100%);
    background: -webkit-linear-gradient(top, $firstColor 0%,$secondColor 100%);
}

@mixin hoverboard() {
    -webkit-transform: translateY(-.25rem);
    transform: translateY(-.25rem);
    box-shadow: 0 .25rem 1rem rgba($black, 0.16);
}