/**
 * Breadcrumb
 */

.breadcrumb {
    color: $body-color;
    font-size: $font-size-sm;
    line-height: 1.5rem;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    width: 100%;

    // &:after {
    //     background-image: linear-gradient(left, transparent, $body-bg);
    //     content: "";
    //     position: absolute;
    //     right: $grid-gutter-width;
    //     top: 0;
    //     height: 3.5rem;
    //     width: $grid-gutter-width;
    // }
    a {
        display: inline-block;
        vertical-align: top;
        color: $body-color;
    }
}