/**
 * Components: Navbar
 */

 $transition-header: .4s ease-in-out;

.navbar {
	// background: $dark;
	background: transparent;
	position: absolute;
	font-family: $font-family-alternative;
	text-transform: uppercase;
	font-weight: $font-weight-bold;
	font-size: $h4-font-size;
	text-align: center;
	padding: 1.5rem;
 	@include media-breakpoint-up(lg) {
    background: transparent;
    padding: .5rem 0 .5rem;
    transition: background $transition-header, top $transition-header;
      &.fixed {
        background: rgba($light-wisteria,.9);
        position: fixed;
        padding-top: 1rem;
        .navbar-brand {
          width: var(--width);
          transition: all $transition-header;
          h2 {
            width: var(--width);
          }
        }
	    }
	}
	@include media-breakpoint-up(lg) {
		padding: 5rem 0 .5rem;
	}

	// Container

	.container {
		position: relative;
	}

	.icon {
		vertical-align: middle;
	}

	// Collapse
	&-collapse {
		@include media-breakpoint-down(md) {
			background: rgba($dark, .96);
		}
	}

	// Toggler

	&-toggler {
		z-index: 20;
		&:focus {
			outline: none;
		}
	}

	&-nav {
		.nav-item{
			margin: 0 .25rem;
			@include media-breakpoint-up(lg){
				// margin: 0 2.5rem;
        margin: 0 1rem;
			}
      @include media-breakpoint-only(lg){
        margin: 0 .5rem;
      }
      &.with-hashtag > a.nav-link::before {
          content: "#";
          color: $primary;
      }
			.nav-link {
				position: relative;
				display: inline-block;
				outline: none;
				color: $white;
				font-weight: $font-weight-bold;
				margin: 0 .75rem;
				&:hover {
					color: $white !important;
				}
				@include media-breakpoint-only(lg){
          margin: 0 .15rem;
        }
				@include media-breakpoint-up(lg){
					color: $white !important;
					&:after {
						position: absolute;
						top: 100%;
						left: 0;
						width: 100%;
						height: 1px;
						background: $primary;
						// background: $yellow;
						content: '';
						opacity: 0;
						-webkit-transition: height 0.3s, opacity 0.3s, -webkit-transform 0.3s;
						-moz-transition: height 0.3s, opacity 0.3s, -moz-transform 0.3s;
						transition: height 0.3s, opacity 0.3s, transform 0.3s;
						-webkit-transform: translateY(-10px);
						-moz-transform: translateY(-10px);
						transform: translateY(-10px);
					}
					&:hover, &:focus {
						&:after {
							height: 5px;
							-webkit-transform: translateY(0px);
							-moz-transform: translateY(0px);
							transform: translateY(0px);
							opacity: 1;
						}
					}
				}
			}
			&.active {
				.nav-link {
          color: $primary;
					&:after {
						height: 5px;
						-webkit-transform: translateY(0px);
						-moz-transform: translateY(0px);
						transform: translateY(0px);
						opacity: 1;
					}
				}
			}
		}
	}
}

.mainnav {
  @include media-breakpoint-down(md) {
		margin-block: 4rem;
	}
  .nav-link:last-child {
		padding-right: 0;
	}
}


.leftnav {
	@include media-breakpoint-down(sm) {
		margin-top: 4rem;
	}
}
.rightnav {
	@include media-breakpoint-down(sm) {
		margin-bottom: 4rem;
	}
	.nav-item:last-child {
		padding-right: 0;
	}
}


// Brand

.navbar-brand {
  --width: 10rem;
  --height: 2.75rem;
  margin: 0;
  padding: 0;
  z-index: 20;
  position: relative;
  width: var(--width);
  height: var(--height);
  transition: all $transition-header;
  @include media-breakpoint-up(lg) {
    width: calc(var(--width) * 1.5);
    height: calc(var(--height) * 1.5);
  }
  h2 {
    background: url(../img/logo.svg) center no-repeat;
    width: var(--width);
    height: var(--height);
    background-size: contain;
    margin: 0;
    text-indent: -999em;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;

      @include media-breakpoint-up(lg) {
        transition: all $transition-header;
        width: calc(var(--width) * 1.5);
        height: calc(var(--height) * 1.5);
      }
  }
}
