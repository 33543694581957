/**
 *  Footer
 */

.footer {
	background-color: $pink;
	color: $white;
	font-size: $font-size-sm;
	> .container {
		padding-bottom: ($spacer * 2);
		padding-top: ($spacer * 2);
		@include media-breakpoint-up(md) {
			padding-bottom: ($spacer * 4);
			padding-top: ($spacer * 4);
		}
	}

	// Links
	a {
		color: $white;
		&:hover, &:focus, &:active, &:active:hover, &:active:focus {
			color: $secondary;
			text-decoration: none;
		}
	}

	// Heading
	h5 {
		font-family: $font-heading;
		font-size: $font-size-base * 1.125;
		margin-bottom: 1rem;
		color: $white;
		text-transform: uppercase;

		@include media-breakpoint-up(md) {
			margin-top: 0;
		}
	}
	p, .form-group {
		margin-bottom: 1rem;
	}

	// List

	ul {
		list-style: none;
		padding-left: 0;
	}

	&-list {
		font-weight: $font-weight-bold;
		column-count: 2;
		margin-top: -.375rem;
		a {
			display: block;
			padding: .375rem 0;
		}
	}
	// Links
	&-links {
		// margin: -.75rem 0;
	}

	// Nav
	&-nav {
		@include list-unstyled();
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		li {
			+ li {
				padding-left: .75rem;
				&:before {
					background: $light;
					display: inline-block;
					content: "";
					border-radius: 50%;
					height: .25rem;
					width: .25rem;
					margin-right: .75rem;
					vertical-align: middle;
                    opacity: .8;
				}
			}
		}
	}

	// Sitemap
	&-sitemap {
		@include media-breakpoint-up(lg) {
			padding-left: 4rem;
		}
	}



	// Social links

	&-social {
		font-weight: $font-weight-bold;
		margin: -.375rem 0 1.75rem;
		.nav-link {
			padding: .375rem 0;
		}
		.icon {
			margin-right: .5rem;
		}
	}

	// Contact

	&-contact {
		p {
			@include media-breakpoint-down(sm) {
				width: 50%;
				float: left;
			}
		}
	}

    // Sponsor
    &-sponsor {
        margin-block: 1rem 2rem;
        a {
            display: block;
            position: relative;
            max-width: 14rem;
            &:after {
                position: absolute;
                content: '';
                inset: 0;
                background-color: $light;
                mask-image: url(../img/nationale-loterij.svg);
                opacity: 0;
                will-change: opacity;
                transition: opacity .25s ease-in-out;
            }
            &:hover:after {
                opacity: 1;
            }
            img {
                max-width: 100%;
                opacity: .96;
            }
        }
        @include media-breakpoint-up(sm) {
            margin-block: 0;
        }
    }

	// Robarov
	.robarov {
		@include media-breakpoint-up(md) {
			text-align: right;
		}
	}

}

// Subfooter

.subfooter {
	padding: 1.5rem 0;
	@include media-breakpoint-down(sm) {
		text-align: center;
	}
  p, ul, a {
    color: rgba($white, .65);
  }
	p, ul {
		@include media-breakpoint-up(md) {
			margin-bottom: 0;
		}
	}
}
