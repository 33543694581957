/**
 * Gallery
 */

$gallery-spacer: $spacer;

.gallery {
    text-align: left;
    &-grid {
        margin: 0 -$gallery-spacer;
        font-size: 0;
    }
    &-img {
        background: $dark;
        @include ratio(1 1);
        @include border-radius($border-radius);
        @include transition($transition-base);
        overflow: hidden;
        -webkit-backface-visibility: hidden;
        &:hover {
            @include hoverboard();
            .lazyloaded {
                opacity: .5;
            }
            &:after {
                opacity: 1;
                transform: translateY(0);
            }
        }
        &:after {
            color: $white;
            font-family: "lg";
            line-height: 1;
            font-size: 2rem;
            content: "\e311";
            height: 2rem;
            width: 2rem;
            margin: -1rem 0 0 -1rem;
            position: absolute;
            left: 50%;
            top: 50%;
            opacity: 0;
            @include transition($transition-base);
            transform: translateY(-1rem);
        }
    }
}
.grid {
    &-item {
        position: relative;
        display: inline-block;
        padding: $gallery-spacer;
        width: 50%;
        vertical-align: top;
        text-decoration: none;
        @include media-breakpoint-up(sm) {
            width: 25%;
        }
    }
    @include media-breakpoint-up(md) {
        &-three-in-a-row {
            .grid-item {
                width: 33.33%;
            }
        }
        &-four-in-a-row {
            .grid-item {
                width: 25%;
            }
        }
        &-five-in-a-row {
            .grid-item {
                width: 20%;
            }
        }
        &-six-in-a-row {
            .grid-item {
                width: 16.66%;
            }
        }
    }
}