/**
 * Icons
 */

.icon {
  fill: currentColor;
  display: inline-block;
  width: 1em;
  height: 1em;
  font-size: 1rem;
  overflow: hidden;
}
.icon, .icon:not(:root) {
    vertical-align: -.2em;
}

img.icon {
    margin: 0 auto;
    height: 2rem;
    width: 2rem;
    @include media-breakpoint-up(md) {
        height: 3rem;
        width: 3rem;
    }
}