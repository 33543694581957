/**
 * Timetable
 */

$columns-number: 4;
$row-height: 4rem;

$event-color-1: $dark;
$event-color-2: $gray-dark;
$event-color-3: #a2b9b2; // Edward
$event-color-4: #f6b067; // Rajah

$border-color: #eaeaea;

.cd-schedule {
    position: relative;
    margin: 2em 0;

    &::before {
        /* never visible - this is used in js to check the current MQ */
        content: "mobile";
        display: none;
    }

    ul {
        list-style: none;
        padding-left: 0;
    }
    a {
        text-decoration: none !important;
    }

    @include media-breakpoint-up(md) {
        width: 90%;
        max-width: $maxwidth;
        margin: 2em auto;
        @include clearfix;

        &::before {
            content: "desktop";
        }
    }
}

.cd-schedule .timeline {
    display: none;

    @include media-breakpoint-up(md) {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding-top: $row-height;

        li {
            position: relative;
            height: $row-height;

            &::after {
                /* this is used to create the table horizontal lines */
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: $border-color;
            }

            &:last-of-type::after {
                display: none;
            }

            span {
                display: none;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        li {
            &::after {
                width: calc(100% - 60px);
                left: 60px;
            }

            span {
                display: inline-block;
                transform: translateY(-50%);
            }

            &:nth-of-type(2n) span {
                display: none;
            }
        }
    }
}

.cd-schedule .events {
    position: relative;
    z-index: 1;

    .events-group {
        margin-bottom: 30px;
    }

    .top-info {
        width: 100%;
        padding: 0 5%;

        > span {
            display: inline-block;
            line-height: 1.2;
            margin-bottom: 10px;
            font-weight: $font-weight-bold;
            color: $primary;
            text-transform: uppercase;
            font-family: $font-heading;
            font-size: $h5-font-size;
        }
    }

    .events-group > ul {
        position: relative;
        padding: 0 5%;
        /* force its children to stay on one line */
        display: flex;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;

        &::after {
            /* never visible - used to add a right padding to .events-group > ul */
            display: inline-block;
            content: "-";
            width: 1px;
            height: 100%;
            opacity: 0;
            color: transparent;
        }
    }

    .single-event {
        /* force them to stay on one line */
        flex-shrink: 0;

        float: left;
        height: 150px;
        width: 70%;
        max-width: 300px;
        box-shadow: inset 0 -3px 0 rgba(#000, 0.2);
        margin-right: 20px;
        transition: opacity 0.2s, background 0.2s;

        &:last-of-type {
            margin-right: 5%;
        }
        // &.nowplaying:last-of-type {
        //   margin-right: 40px;
        // }

        a {
            display: block;
            height: 100%;
            padding: 0.8em;
        }
    }

    @include media-breakpoint-up(sm) {
        .single-event {
            width: 40%;
        }
    }

    @include media-breakpoint-up(md) {
        float: left;
        width: 100%;

        .events-group {
            width: 100% / $columns-number;
            float: left;
            border: 1px solid $border-color;
            /* reset style */
            margin-bottom: 0;

            &:not(:first-of-type) {
                border-left-width: 0;
            }

            @for $j from 1 through 10 {
                $stageWidth: 100% / $j;

                &.stages-#{$j} {
                    width: $stageWidth;
                }
            }
        }

        .top-info {
            /* vertically center its content */
            display: table;
            height: $row-height;
            border-bottom: 1px solid $border-color;
            /* reset style */
            padding: 0;

            > span {
                /* vertically center inside its parent */
                display: table-cell;
                vertical-align: middle;
                padding: 0 0.5em;
                text-align: center;
                /* reset style */
                margin-bottom: 0;
            }
        }

        @for $i from 1 through 30 {
            $eventHeight: $row-height * $i;

            .events-#{$i} {
                height: $eventHeight;
            }
        }

        .events-group > ul {
            @include clearfix;
            /* reset style */
            display: block;
            overflow: visible;
            padding: 0;

            &::after {
                /* reset style */
                display: none;
            }
        }

        .single-event {
            position: absolute;
            z-index: 3;
            /* top position and height will be set using js */
            width: calc(100% + 2px);
            left: -1px;
            box-shadow: 0 10px 20px rgba(#000, 0.1), inset 0 -3px 0 rgba(#000, 0.2);
            /* reset style */
            flex-shrink: 1;
            height: auto;
            min-height: $row-height;
            max-width: none;
            margin-right: 0;

            a {
                padding: 0.75rem 1rem;
            }

            &:last-of-type {
                /* reset style */
                margin-right: 0;
            }

            &.selected-event {
                /* the .selected-event class is added when an user select the event */
                visibility: hidden;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        /* 60px is the .timeline element width */
        width: calc(100% - 60px);
        margin-left: 60px;
    }
}

.cd-schedule.loading .events .single-event {
    /* the class .loading is added by default to the .cd-schedule element
     it is removed as soon as the single events are placed in the schedule plan (using javascript) */
    opacity: 0;
}

.cd-schedule .event-name,
.cd-schedule .event-date {
    display: block;
    color: white;
    font-weight: bold;
}

.cd-schedule .event-name {
    font-size: $font-size-base;

    @include media-breakpoint-up(md) {
        // font-size: $font-size-lg;
    }
}

.cd-schedule .event-date {
    /* they are not included in the the HTML but added using JavScript */
    font-size: $font-size-sm;
    opacity: 0.7;
    line-height: 1.25rem;
}

.cd-schedule .single-event:nth-child(odd) {
    background: $event-color-1;
    &:hover {
        background: lighten($event-color-1, 5%);
    }
}

.cd-schedule .single-event:nth-child(even) {
    background: $event-color-2;
    &:hover {
        background: lighten($event-color-2, 5%);
    }
}

.single-event {
    @include transition($transition-color);
    &:hover {
        .event-name,
        .event-date {
            color: $primary;
        }
    }
}

.cd-schedule .event-modal {
    position: fixed;
    z-index: 3;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    visibility: hidden;
    /* Force Hardware acceleration */
    transform: translateZ(0);

    transform: translateX(100%);
    transition: transform 0.4s, visibility 0.4s;
    transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1);

    .header {
        position: relative;
        height: 70px;
        /* vertically center its content */
        display: table;
        width: 100%;

        .content {
            position: relative;
            z-index: 3;
            /* vertically center inside its parent */
            display: table-cell;
            vertical-align: middle;
            padding: 0.6em 5%;
        }
    }

    .body {
        position: relative;
        width: 100%;
        /* 70px is the .header height */
        height: calc(100% - 70px);
    }

    .event-info {
        position: relative;
        z-index: 2;
        line-height: 1.4;
        height: 100%;
        overflow: hidden;

        > div {
            overflow: auto;
            height: 100%;
            padding: 1.4em 5%;
        }
    }

    .header-bg,
    .body-bg {
        /* these are the morphing backgrounds - visible on desktop only */
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    .body-bg {
        z-index: 1;
        background: $body-bg;
        transform-origin: top left;
    }

    .header-bg {
        z-index: 2;
        transform-origin: top center;
    }

    .close {
        /* this is the 'X' icon */
        position: absolute;
        top: 0;
        right: 0;
        z-index: 3;
        background: rgba(#000, 0.1);
        /* replace text with icon */
        color: transparent;
        white-space: nowrap;
        text-indent: 100%;
        height: 70px;
        width: 70px;

        &::before,
        &::after {
            /* these are the two lines of the 'X' icon */
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 2px;
            height: 22px;
            background: $body-bg;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
        }

        &::before {
            transform: translateX(-50%) translateY(-50%) rotate(45deg);
        }

        &::after {
            transform: translateX(-50%) translateY(-50%) rotate(-45deg);
        }
    }

    .event-date {
        display: none;
    }

    &.no-transition {
        transition: none;

        .header-bg,
        .body-bg {
            transition: none;
        }
    }

    @include media-breakpoint-up(md) {
        /* reset style */
        right: auto;
        width: auto;
        height: auto;
        transform: translateX(0);
        will-change: transform, width, height;
        transition: height 0.4s, width 0.4s, transform 0.4s, visibility 0.4s;
        transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1);

        .header {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            height: 100%;

            .content {
                /* reset style */
                display: block;
                padding: 0.8em;
            }
        }

        .event-info > div {
            padding: 2em 3em 2em 2em;
        }

        .body {
            height: 100%;
            width: auto;
        }

        .header-bg,
        .body-bg {
            /* Force Hardware acceleration */
            transform: translateZ(0);
            will-change: transform;
            backface-visibility: hidden;
        }

        .header-bg {
            transition: transform 0.4s;
            transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1);
        }

        .body-bg {
            opacity: 0;
            transform: none;
        }

        .event-date {
            display: block;
        }

        .close,
        .event-info {
            opacity: 0;
        }

        .close {
            width: 40px;
            height: 40px;
            background: transparent;

            &::after,
            &::before {
                // background: $body-color;
                background: $secondary;
                height: 16px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .header .content {
            padding: 1.2em;
        }
    }
}

.cd-schedule.modal-is-open .event-modal {
    /* .modal-is-open class is added as soon as an event is selected */
    transform: translateX(0);
    visibility: visible;

    .event-info > div {
        /* smooth scroll on iOS touch devices */
        -webkit-overflow-scrolling: touch;
    }
}

@include media-breakpoint-up(md) {
    .cd-schedule.animation-completed .event-modal .close,
    .cd-schedule.content-loaded.animation-completed .event-modal .event-info {
        /*  the .animation-completed class is added when the modal animation is completed
      the .content-loaded class is added when the modal content has been loaded (using ajax) */
        opacity: 1;
        transition: opacity 0.2s;
    }

    .cd-schedule.modal-is-open .body-bg {
        opacity: 1;
        transition: transform 0.4s;
        transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1);
    }
}

.cd-schedule .cover-layer {
    /* layer between the content and the modal window */
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(#000, 0.8);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s, visibility 0.4s;
}

.cd-schedule.modal-is-open .cover-layer {
    opacity: 1;
    visibility: visible;
}

@include media-breakpoint-down(md) {
    .section-timetable {
        .nav {
            display: block;
            width: 100%;
            &-item {
                width: 100%;
                .nav-link {
                    width: 100%;
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }
}

.timetable-buttons {
    margin-bottom: 1rem;
    margin-top: 1rem;
    .btn {
        display: block;
    }
    @include media-breakpoint-up(md) {
        width: 100%;
        margin-top: 0;
        margin-bottom: 2rem;
        .btn {
            display: inline-block;
            width: 48.5%;
            &:nth-child(2) {
                float: right;
            }
        }
    }
}
