/**
 *  Forms
 */


.form {
  .form-group, .yourssince1615__wrapper {
    @include make-col-ready();
    // @extend .col-md-12;
    // @extend .col-md-6;
    input[type="text"], input[type="email"], select, textarea {
      @extend .form-control;
      font-size: inherit;
      font-weight: inherit;
      &.parsley-error {
        @extend .form-control.is-invalid;
        &:focus {
          border-color: $form-feedback-invalid-color;
        }
      }
      &.parsley-success:focus {
        @extend .form-control.is-valid;
        border-color: $form-feedback-valid-color;
      }
    }
  }
}
.form-group {
  text-align: left;
  > label {
    @include font-small();
  }
}

.sidebar {
  &-form {
    .form {
      @extend .card;
      @extend .card-body;
      flex: none;
      margin-bottom: $paragraph-margin-bottom;
      p {
          font-size: $font-size-sm;
      }
    }
  }
}

 .form-group:not(.fullwidth) {
    @extend .col-md-6;
  }

.bg-dark {
  label {
    color: $white;
  }
  .form-control {
    background: rgba($white, .08);
    border-color: transparent;
    color: white;
  }
}

.form-chore {
  position: relative;
}

.chore__note {
    display: none;
    position: relative;
    border: .25rem solid $secondary;
    padding: $spacer $spacer $spacer $spacer * 3;
    color: $secondary;
    margin-bottom: $spacer;
    @include media-breakpoint-up(md) {
        position: absolute;
        margin-bottom: 0;
        width: 47%;
        right: 0;
        // background: $brand-success;
        top: 290px;
  }
  @include media-breakpoint-up(lg) {
    top: 300px;
  }

  &:before {
    content:'';
    display: block;
    // background: url(../img/info.svg) no-repeat;
    mask: url(../img/info.svg);
    background-color: $secondary;
    background-size: contain;
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    left: $spacer;
    top: $spacer;
    @include media-breakpoint-up(md) {
      top: $spacer * 1.75;
    }
  }
  &.visible {
    display: block;
  }
}

label, .label {
  .required {
    font-family: sans-serif;
    font-size: $font-size-lg;
    position: relative;
    top: .125rem;
    line-height: .5;
    color: $danger;
  }
}
.label {
  margin-bottom: $label-margin-bottom;
}

.alert {
  border: 0;
  padding: $spacer * 2;
  margin-bottom: $spacer * 2;
  color: white;
  overflow: hidden;
  &-danger {
    background: $brand-danger;
  }
  &-success {
    background: $brand-success;
    color: $secondary;
  }
  h2, h3, h4 {
    color: white;
  }
}


.checkboxes {
  > div {
    @extend .custom-control;
    @extend .custom-checkbox;
  }
}

.radiobuttons {
  > div {
    @extend .custom-control;
    @extend .custom-radio;
  }
}

.checkboxes, .radiobuttons {
  input {
    @extend .custom-control-input;
    + label {
        @extend .custom-control-label;
        line-height: 1.5rem;
        cursor: pointer;
    }
  }
}

.file {
  @extend .custom-file;
  input {
    @extend .custom-file-input;
    &.parsley-error {
      + ul + label {
        @extend .form-control.is-invalid;
        &:focus {
          border: 2px solid $white;
        }
      }
    }
  }
  label {
      @extend .custom-file-label;
      overflow: hidden;
      white-space: nowrap;
  }
}

html:lang(nl) {
  .file label:after {
    content: "Bestand kiezen";
  }
}

.form__note {
  font-size: $font-size-sm;
  @extend .form-group;
}

.parsley-errors-list {
  @include list-unstyled();
  margin: 0;
  li {
    @extend .invalid-feedback;
    display: block;
  }
}

.break {
  width: 100%;
}
.recaptcha {
  margin-bottom: 1rem;
  padding-left: .625rem;
}

.gdpr {
  font-size: $font-size-sm;
  margin-top: 1.5rem;
}

.contactbox {
  background: $white;
  color: $secondary;
  padding: 1.5rem;
  border: .5rem solid $primary;
  border-image: $border-attention;
  h2 {
    font-size: $h3-font-size;
  }
  a {
    color: $secondary;
    text-decoration: underline;
    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
}

.subscribe-form{
  margin-bottom: 2rem;
}