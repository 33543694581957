/**
 * Section
 */

$blur: .5rem;

html {
	// background: $body-color;
	background: $secondary;
	scroll-behavior: smooth;
}
#content {
	min-height: 40rem;
}

.container {
	max-width: $maxwidth;
	@extend .container-fluid;
	// More (double) padding left and right vs regular Bootstrap padding
	padding: 0 $grid-gutter-width;
	> .content {
		margin-left: auto;
		margin-right: auto;
	}
}

.text-center {
	.content {
		margin-left: auto;
		margin-right: auto;
	}
}

.content {
	padding: $spacer 0;
	position: relative;
	z-index: 1;
}

.main {
	@extend .col-md-12;
}
.sidebar {
	@extend .col-md-4;
	@extend .col-lg-3;
	margin-bottom: 2rem;
	+ .main {
		@extend .col-md-8;
		@extend .col-lg-9;
	}
}

// COMPONENTS

.richtext,
.form,
.button,
.downloads {
	max-width: 44rem;
}
.video,
.image,
.slideshow,
.map-wrap {
	max-width: 56rem;
}
.gallery,
.teasers,
.map-cover {
	max-width: none;
}

.cover {
	background: $white;
	@include ratio(4 3);
	margin: (-$spacer * 2) (-$grid-gutter-width) ($spacer * 2);
	margin-bottom: 20;
	@include media-breakpoint-up(md) {
		// position: absolute;
		top: 0;
		bottom: 0;
		padding: 0;
		margin: 0;
	}
	&:before {
		// border: 15px solid pink;
		display: none;
		padding-bottom: 0;
	}
	img {
		height: 100%;
	}
}

// .cover {
// 	background: $light;
// 	@include ratio(4 3);
// 	margin: (-$spacer * 2) (-$grid-gutter-width) ($spacer * 2);
// 	@include media-breakpoint-up(md) {
// 	  // position: absolute;
// 	  top: 0;
// 	  bottom: 0;
// 	  padding: 0;
// 	  margin: 0;
// 	}
// 	img {
// 	  height: 100%;
// 	}
//   }

.column + .column {
	.cover {
		margin: ($spacer * 2) (-$grid-gutter-width) (-$spacer * 2);
		@include media-breakpoint-up(md) {
			margin: 0;
		}
	}
}

// .column + .column {
//   .cover {
//       margin: ($spacer * 2) (-$grid-gutter-width) (-$spacer * 2);
//       @include media-breakpoint-up(md) {
//         margin: 0;
//       }
//   }
// }

// SECTION

.section {
	margin: auto;
	position: relative;
	overflow: hidden;
	padding: ($spacer * 2) 0;
	@include media-breakpoint-up(md) {
		padding: ($spacer * 4) 0;
	}
	&-sm {
		padding: $spacer;
		@include media-breakpoint-up(md) {
			padding: ($spacer * 2) 0;
		}
	}
	&-lg {
		padding: ($spacer * 4) 0;
		@include media-breakpoint-up(md) {
			padding: ($spacer * 8) 0;
		}
	}

	&.boxed {
		@include media-breakpoint-up(sm) {
			width: calc(100% - #{$grid-gutter-width * 2});
			max-width: $maxwidth;
		}
		@include media-breakpoint-up(xl) {
			margin: auto;
		}
	}

	&:not(.hasbg) + .section-1column:not(.hasbg) {
		padding-top: 0;
	}

	// Columns

	.column {
		@include make-col-ready();
		display: flex;
		flex-direction: column;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-grow: 1;
		.cover {
			@include media-breakpoint-up(md) {
				top: -($spacer * 4);
				bottom: -($spacer * 4);
			}
		}
	}

	&-2columns {
		.column {
			@extend .col-md-6;
			+ .column {
				.cover {
					margin: ($spacer * 2) (-$grid-gutter-width) (-$spacer * 2);
					@include media-breakpoint-up(md) {
						margin: 0;
						right: auto;
						left: $spacer * 2;
					}
				}
			}
		}
		.cover {
			padding: 0;
			margin: (-$spacer * 2) (-$grid-gutter-width) ($spacer * 2);
			@include media-breakpoint-up(md) {
				position: absolute;
				top: 0;
				bottom: 0;
				margin: 0;
				left: auto;
				right: $spacer * 2;
				width: 50vw;
				max-width: none;
			}
			figure {
				@include media-breakpoint-up(md) {
					position: absolute;
					width: 100%;
					height: 100%;
					border-radius: 0;
				}
			}
			figure,
			img {
				margin: 0;
				border-radius: 0;
			}
		}
	}

	&-3columns {
		.column {
			@extend .col-md-4;
		}
	}

	&-4columns {
		.column {
			@extend .col-md-3;
		}
	}

	&.col-1-3-2-3 {
		.column {
			@extend .col-md-5;
			@extend .col-lg-4;
			+ .column {
				@extend .col-md-7;
				@extend .col-lg-8;
				.cover {
					@include media-breakpoint-up(lg) {
						width: 60vw;
					}
				}
			}
		}
		.cover {
			@include media-breakpoint-up(lg) {
				width: 40vw;
			}
		}
	}

	&.col-2-3-1-3 {
		.column {
			@extend .col-md-7;
			@extend .col-lg-8;
			+ .column {
				@extend .col-md-5;
				@extend .col-lg-4;
				.cover {
					@include media-breakpoint-up(lg) {
						width: calc(40vw + #{$grid-gutter-width / 2});
					}
				}
			}
		}
		.cover {
			@include media-breakpoint-up(lg) {
				width: calc(66.66vw - #{$grid-gutter-width / 2});
			}
		}
	}

	// On LG, more grid-gutter-width

	&-2columns,
	&-3columns {
		.container {
			@include media-breakpoint-up(lg) {
				> .row {
					margin-left: -$grid-gutter-width;
					margin-right: -$grid-gutter-width;
					.column {
						padding-left: $grid-gutter-width;
						padding-right: $grid-gutter-width;
					}
				}
			}
		}
	}

	// Fullscreen
	&-fullscreen {
		@include media-breakpoint-up(md) {
			padding: 0;
			height: 100vh;
			.container,
			.container > .row {
				height: 100%;
			}
			.container > .row {
				align-items: center;
			}
		}
	}

	// Stretch
	&.stretch {
		padding: 0;
		max-width: none;
		.container,
		.map-wrap {
			padding: 0;
			max-width: none;
		}
		.cta {
			max-width: none;
		}
		.cta,
		.map {
			border-radius: 0;
		}
	}

	// Padding

	&-no-padding {
		padding: 0;
		&-top {
			padding-top: 0;
		}
		&-bottom {
			padding-bottom: 0;
		}
	}

	// Tabs

	&-tabs {
		background: rgba($primary, 0.1);
		.nav {
			padding: 1rem 0.5rem;
			&-link {
				color: $light-text;
				&:hover,
				&.active {
					color: white;
				}
			}
		}
		.tab-content {
			padding: $grid-gutter-width / 2;
			h2,
			h3 {
				color: $primary;
			}
		}
	}

	// Locations

	&-locations {
		.nav-tabs {
			height: 6rem;
		}
	}

	// Quote

	&_quote {
		h3 {
			color: $primary;
			max-width: 80%;
		}
	}
}

// Backgrounds
.bg-cta  {
	background-color: $primary;
}
.bg-light + .bg-light,
.bg-dark + .bg-dark {
	padding-top: 0 !important;
	margin-top: -$spacer;
	@include media-breakpoint-up(md) {
		margin-top: -($spacer * 2);
	}
	p {
		color: white;
	}
}

.bg-light {
	background-color: $light-wisteria !important;
	p,
	tr {
		color: $secondary;
	}
	h3,
	h4,
	h5 {
		color: $secondary;
	}
}

.bg-dark,
.bg-cover {
	background-color: $dark;
	color: $light-text;
	h2,
	h3,
	h4,
	dt {
		color: $white;
	}
	a:not([class]) {
		color: $white;
		&:hover {
			color: $primary;
		}
	}
}

.overlay {
	background: $dark;
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	z-index: 0;
}

.bg-cover {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	// z-index: 0;
	&.bg-blur {
		filter: blur($blur);
		-webkit-filter: blur($blur);
		-moz-filter: blur($blur);
		-o-filter: blur($blur);
		-ms-filter: blur($blur);
		margin: -($blur * 2);
	}

	img {
		width: 100%;
		height: 100%;
	}
}

.bg-video {
	&-media {
		position: absolute;
		width: 100%;
		height: 100%;
		transform: none;
		object-fit: cover;
		transition-delay: 2s;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		// z-index: 0;
	}
}

.target {
	display: block;
	@include media-breakpoint-up(md) {
		height: 120px;
		margin-top: -120px;
	}
}

// .pink {
//     border: 2px solid $pink;
// }

.bg-pink {
    background-color: $pink;
}

.bg-yellow {
  background-color: $yellow;
}

.bg-light-wisteria {
  background-color: $light-wisteria;
}
