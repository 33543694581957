/**
 * Images
 */

figure {
    @include transition($transition-base);
    background: $light;
    margin-bottom: $spacer;
    @include border-radius($border-radius);
    text-align: center;
    overflow: hidden;
    @include ratio(4 3);
}
.section-1column {
    figure {
        @include media-breakpoint-up(md) {
            @include ratio(16 9);
        }
    }
    .image {
        max-width: 44rem;
    }
}

img {
    @extend .img-fluid;
}

.image {
    text-align: center;
    img {
        @include border-radius($border-radius);
    }
}


.lazyload, .lazyloading {
    opacity: 0;
}
.lazyloaded {
    opacity: 1;
    @include transition(opacity .2s ease-in);
}

.faded {
    .lazyloaded {
        opacity: .4;
    }
}