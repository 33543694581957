/**
 * Search
 */

.search {
    &-results {
        h3 {
            color: $primary;
            margin: 0;
            @include font-base();
        }
    }
}