// Variables
$maxwidth: 74.25rem;
$spacer: 1rem;

// Colors

// Brand colors
$white: #fff;
$primary: #00B140;
$secondary: #4F2F7C;
$gray-light: #ccc;
$gray-lighter: #eee;
$gray: #9B9B9B;
$gray-900: #00050c;
$gray-dark: #333;
$muted: #AAA;
$light: #F0F0F0;
$orange: #FF8200;

$light-wisteria: #C1A0DA;
$red: #DA291C;
$yellow: #FCDC00;

$pink: #E89CAE;
$brand-danger: $pink;
$robared: #e1523d;
$brand-warning: #080707;
$brand-success: $primary;
$light-text: rgba(255,255,255, .72);

$dark: $secondary;
$gray-dark: $gray-900;

// Body

$body-color: $gray-dark;
$paragraph-margin-bottom: 1.5em;

// Fonts

$font-heading: "Aptos", Arial, sans-serif;
$font-family-sans-serif: "Aptos", Arial, sans-serif;
$font-family-alternative: "Aptos", Arial, sans-serif;
// $font-family-alternative: "acumin-pro-extra-condensed", Arial, sans-serif;
$font-size-base: 1rem;
$font-size-sm:   .875rem;
$font-size-xs: .75rem;
$transition-color: color 0.2s ease-in-out;

$font-weight-light: 300;
$font-weight-base: 400;
$font-weight-bold: 700;

$line-height-base: 1.5;

$enable-rounded: true;

$headings-font-weight: $font-weight-bold;
$headings-margin-bottom: 1rem;

$headings-color: $secondary;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;

$blockquote-font-size: 1rem;

$text-muted: $muted;

$link-color: $secondary;
// $link-hover-color: $primary;


$hr-border-color: $primary;
$hr-border-width: 2px;
$hr-margin-y: .5rem;

$grid-gutter-width: 2rem;

// Navbar
$navbar-height: 70px;

$navbar-padding-x: 0rem;
$navbar-padding-y: 1rem;
$navbar-nav-link-padding-x: 0rem;
$nav-link-padding-y: .25rem;

$navbar-light-color: $white;
$navbar-light-bg: transparent;
$navbar-light-hover-color: white;
$navbar-light-active-color: white;

$navbar-dark-color: rgba(white,.8);
$navbar-dark-hover-color: white;
$navbar-dark-active-color: white;

$navbar-toggler-padding-y: .25rem;
$navbar-toggler-padding-x: .25rem;
$navbar-light-toggler-border-color: transparant;


// Components

$card-bg: $light;
$card-cap-bg: rgba(black, .08);
$card-border-color: $light;
$card-spacer-y: 1.25rem;
$card-spacer-x: 1.5rem;

$custom-control-indicator-bg: darken($light,12%);
$custom-control-indicator-disabled-bg: darken($light,6%);

$custom-file-button-bg:$light;



// Paddings

$input-btn-padding-y-lg:      .75rem;
$input-btn-padding-x-lg:      1.5rem;
$input-btn-line-height-lg:    $line-height-base;

$input-btn-padding-y: .5rem;
$input-btn-padding-x: 1rem;
$input-btn-focus-width: 0;


$form-group-margin-bottom: 1.5rem;


// Buttons

// $input-btn-font-family: $font-family-alternative;
$btn-primary-bg: $primary;
$btn-border-radius: 0;

// Forms

$input-border-color: $gray-light;
$input-focus-border-color: darken($input-border-color, 16%);

$input-border-radius: 0;
$input-border-radius-lg: 0;
$input-border-radius-sm: 0;


// Tables
$table-border-color: $gray-light;

// Tabs

$nav-tabs-border-color: white;
$nav-tabs-border-width: 2px;
$nav-tabs-border-radius: 0;
$nav-tabs-link-hover-border-color: transparent transparent $brand-danger transparent;
$nav-tabs-link-active-color: $dark;
$nav-tabs-link-active-bg: white;
$nav-tabs-link-active-border-color: $nav-tabs-link-hover-border-color !default;


// Breadcrumbs

$breadcrumb-margin-bottom: 0;
$breadcrumb-padding-y: 1rem;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding: 1rem;
$breadcrumb-bg: transparent;
$breadcrumb-border-radius: 0;
$breadcrumb-divider: "\203A";
$breadcrumb-divider-color: $muted;

// Pagination

$pagination-color: $gray;
$pagination-border-width: 0;
$pagination-hover-color: $primary;
$pagination-hover-bg: $light;

$border-radius: 0;
$border-attention: repeating-linear-gradient( -45deg, $pink, $pink .5rem, transparent .5rem, transparent 1rem) 10;
