/**
 * Frontboxes
 */

.section-frontboxes {
		+ .section {
		overflow: visible;
	}
}

.frontboxes {
	position: relative;
	overflow: hidden;
	margin-top: -8rem;
}

.frontbox-content {
  position: relative;

  &::before {
    --size: 7.5rem;
    position: absolute;
    content: '';
    width: 100%;
    left: -50%;
    transform: translateX(50%);
    background: url(../img/logo.svg) center no-repeat;
    background-size: contain;
    top: -150%;
    height: var(--size);
    @include media-breakpoint-down(sm) {
      display: none;
    }
    @include media-breakpoint-down(md) {
      height: calc(var(--size) * .9);
      top: -125%;
    }
    @include media-breakpoint-up(lg) {
      top: calc(-1.2 * var(--size));
    }
  }
}

.frontbox {
	position: relative;
	// height: 72vh;
	// min-height: 40rem;
  height: 0;
	overflow: hidden;
  padding-bottom: 75%;
  @include media-breakpoint-up(lg) {
    padding-bottom: 0;
    height: 40rem;
  }
	.slide {
    // height: 72vh;
    height: 100%;
		width: 100%;
	}
	.bg-cover {
        background: $dark;
	    &:before {
	    	content: "";
	    	display: block;
	    	position: absolute;
	    	height: 40%;
	    	width: 100%;
	    	top: 0;
	    	left: 0;
	    	@include linear-gradient(rgba($light-wisteria,1),rgba($light-wisteria,0));
	    	z-index: 1;
	    }
	}
	.container {
    @include media-breakpoint-up(lg) {
      position: relative;
    }
		position: absolute;
		z-index: 3;
		width: 100%;
		height: 100%;
	}
	.row {
		height: 100%;
		-webkit-box-align: flex-end;
		-ms-flex-align: flex-end;
		align-items: flex-end;
	}
	&-content {
		margin-right: auto;
		margin-left: auto;
    padding-bottom: $grid-gutter-width;
    @include media-breakpoint-up(md) {
        padding-bottom: $grid-gutter-width * 2;
    }
		/*background-color: $primary;*/
    /*border: .5rem solid $white;*/
    /*border-image: repeating-linear-gradient( -45deg, $secondary, $secondary 2%, $primary 2%, $primary 4%) 7.5;*/
		// border-radius: 40% 80% 80% 40% / 40% 40% 80% 80%;
    /*padding: 8rem 4rem;*/
		@extend .col-lg-6;
    // h1 strong {
    //   position: relative;
    //   &:before {
    //       background: url(../img/decoration.svg) 0 0 no-repeat;
    //       background-size: cover;
    //       content: "";
    //       position: absolute;
    //       bottom: -.5rem;
    //       right: 0;
    //       height: 1.5rem;
    //       width: 7rem;
    //       margin: -.5rem -.5rem 0 0;
    //   }
    // }
    // @include media-breakpoint-up(md) {
    //     margin-top: 16rem;
    // }
	}

	h1, p {
		color: $white;
		// font-family: $font-family-sans-serif;
    }
    h1 {
    	font-weight: $font-weight-light;
        @include media-breakpoint-up(lg) {
            font-size: 3rem;
        }
    }
    p {
    	margin-bottom: 1rem;
        @include media-breakpoint-up(lg) {
            font-size: 2rem;
            margin-bottom: 1.5rem;
        }
    }
    .btn {
    	&-primary {
    		@extend .btn-secondary;
    	}
        &-outline-primary {
            @extend .btn-outline-secondary;
        }
    }
    .overlay {
      z-index: 2;
    }
}

/* Background video */
  .background-video {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      overflow: hidden;
  }
.foreground-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.background-video iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 250vw;
    transform: translate(-50%, -50%);
    pointer-events: none;
    @include media-breakpoint-up(md) {
        height: 75vw;
    }
}

/* Overlay video */
#video-overlay {
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}


.blips {
 width:600px;
 height:600px;
 margin:0 auto;
 position:absolute;
 left:50%;
 top:50%;
 z-index: -1;
 /*display:;*/
 margin-left:-300px;
 margin-top:-300px;
}
.blip {
  position: absolute;
  text-align: center;
  color: white;
  -webkit-animation-timing-function:linear;
  -moz-animation-timing-function:linear;
  animation-timing-function:linear;
}
.blip:nth-child(1) {
  width:400px;
  height:400px;
  margin-top:50px;
  margin-left:50px;
  /* border:1px solid black; */
  -webkit-animation: orbit 3s infinite linear;
  -moz-animation: orbit 3s infinite linear;
  animation: orbit 3s infinite linear;
}
.blip:nth-child(2){
    width:400px;
    height:400px;
    -webkit-animation: orbit 4s infinite linear;
    -moz-animation: orbit 4s infinite linear;
    animation: orbit 4s infinite linear;
}
.blip:nth-child(2){
    width:400px;
    height:400px;
    -webkit-animation: orbit 4s infinite linear;
    -moz-animation: orbit 4s infinite linear;
    animation: orbit 4s infinite linear;
}
.blip:nth-child(3){
    width:400px;
    height:300px;
    -webkit-animation: orbit_neg 4s infinite linear;
    -moz-animation: orbit_neg 4s infinite linear;
    animation: orbit_neg 4s infinite linear;

}
.blip:before {
  content:'';
  font-size:0px;
  border-radius:100%;
}
.blip:nth-child(1):before {
  padding:5px;
  background: $primary;
}
.blip:nth-child(2):before {
  padding:5px;
  position:absolute;
  margin-top:100px;
  margin-left:120px;
  background: $primary;
  -webkit-animation: blip_scale 5s infinite cubic-bezier(1.000, -0.400, 0.100, 1.210);
  -moz-animation: blip_scale 5s infinite cubic-bezier(1.000, -0.400, 0.100, 1.210);
  animation: blip_scale 5s infinite cubic-bezier(1.000, -0.400, 0.100, 1.210);
}
.blip:nth-child(3):before {
  padding:2px;
  position:absolute;
  border: 2px solid  $primary;
  -webkit-animation: orbit_neg 4s infinite linear;
  -moz-animation: orbit_neg 4s infinite linear;
  animation: orbit_neg 4s infinite linear;
}
.blip:nth-child(4):before {
  padding:4px;
  position:absolute;
  margin-top:370px;
  margin-left:340px;
  background: $primary;
  -webkit-animation-delay: 9s; /* Chrome, Safari, Opera */
  animation-delay: 9s;
  -webkit-animation: blip_fade 3s infinite;
  -moz-animation: blip_fade 3s infinite;
  animation: blip_fade 3s infinite;
}

/* Mozila + Chrome */
@keyframes blip_scale {
  0% {transform: scale(.6);}
  100% {transform: scale(.1);}}
@keyframes blip_fade { 0% {opacity:1; }  100% {opacity:.1; }}
@keyframes orbit {
    0%   {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}}
@keyframes orbit_neg {
    0%   {transform: rotate(0deg);}
    100% {transform: rotate(-360deg);}
}
/* Safari */
@-webkit-keyframes blip_scale {
  0% {-webkit-transform: scale(.6);}
  100% {-webkit-transform: scale(.1);}}
@-webkit-keyframes blip_fade { 0% {opacity:1; } 100% {opacity:.1; }}
@-webkit-keyframes orbit {
     0%   {-webkit-transform: rotate(0deg);}
    100% {-webkit-transform: rotate(360deg);} }
@-webkit-keyframes orbit_neg {
    0%   {-webkit-transform: rotate(0deg);}
    100% {-webkit-transform: rotate(-360deg);}
}


.blobs {
 width:320px;
 height:320px;
 margin:0 auto;
 position:absolute;
 left:50%;
 top:50%;
 margin-left:-180px;
 margin-top:-180px;
 border-radius:100%;
 -webkit-animation: myturn linear 16s infinite;
 -moz-animation: myturn linear 16s infinite;
 z-index: -1;
 opacity: .9;
}
.blob {
  position: absolute;
  background: rgba($primary,.8);
  text-align: center;
  color: white;
}

.blob:nth-child(1) {
  top: -10px;
  left: -10px;
  width: 250px;
  height: 260px;
  border-radius: 100%;
  /*display:;*/
  -webkit-animation: mytopdown linear 3s infinite;
  -moz-animation: mytopdown linear 3s infinite;
  animation: mytopdown linear 3s infinite;
}
.blob:nth-child(2) {
  top: -10px;
  left: -10px;
  width: 250px;
  height: 250px;
  border-radius: 100%;
  /*display:;*/
  -webkit-animation: mydowntop linear 3s infinite;
  -moz-animation: mydowntop linear 3s infinite;
  animation: mydowntop linear 3s infinite;
}
.blob:nth-child(3) {
  top: 10px;
  left: -10px;
   width: 250px;
  height: 250px;
  border-radius: 100%;
  /*display:;*/
  -webkit-animation: mytopleft linear 5s infinite;
  -moz-animation: mytopleft linear 5s infinite;
  animation: mytopleft linear 5s infinite;
}
.blob:nth-child(4) {
  top: -10px;
  left: 10px;
  border-radius: 45%;
  width: 240px;
  height: 250px;
  /*display:;*/
  -webkit-animation: mydownright linear 3s infinite;
  -moz-animation: mydownright linear 3s infinite;
  animation: mydownright linear 3s infinite;
}
.blob:nth-child(5) {
  top: -10px;
  left: -10px;
   width: 240px;
  height: 250px;
  border-radius: 42%;
  /*display:;*/
  -webkit-animation: mytopright linear 4s infinite;
  -moz-animation: mytopright linear 4s infinite;
  animation: mytopright linear 4s infinite;
}
.blob:nth-child(6) {
  top: -10px;
  left: -10px;
   width: 240px;
  height: 250px;
  border-radius: 47%;
  -webkit-animation: mydownleft linear 4s infinite;
  -moz-animation: mydownleft linear 4s infinite;
  animation: mydownleft linear 4s infinite;
}

/* Mozila + Chrome */
@keyframes mytopdown {
    0%   {top: 0px; transform: rotate(0deg) scale(1);}
    50%  {top: 10px;  transform: rotate(180deg) scale(1.04);}
    100% {top: 0px; transform: rotate(360deg) scale(1);}
}
@keyframes mydowntop {
    0%   {top: 0px;  transform: rotate(0deg);}
    50%  {top: -10px;  transform: rotate(-180deg);}
    100% {top: 0px;  transform: rotate(-360deg);}
}
@keyframes mytopleft {
    0%   {left: 0px;top:0px; transform: rotate(0deg);}
    50%  {left: 15px; top:5px; transform: rotate(180deg);}
    100% {left: 0px; top:0px; transform: rotate(360deg);}
}
@keyframes mydownright {
    0%   {left: 0px;top:0px; transform: rotate(0deg);}
    50%  {left: -10px; top:-5px; transform: rotate(180deg);}
    100% {left: 0px; top:0px; transform: rotate(360deg);}
}
@keyframes mytopright {
    0%   {left: 0px;top:0px; transform: rotate(0deg);}
    50%  {left: -10px; top:5px; transform: rotate(180deg);}
    100% {left: 0px; top:0px; transform: rotate(360deg);}
}
@keyframes mydownleft {
    0%   {left: 0px;top:0px; transform: rotate(0deg);}
    50%  {left: 10px; top:10px; transform: rotate(-180deg);}
    100% {left: 0px; top:0px; transform: rotate(-360deg);}
}
@keyframes myturn {
    0%   {transform: rotate(0deg) skewX(0deg);}
    100% {transform: rotate(-360deg) skewX(0deg);}
}


/* Safari */
@-webkit-keyframes mytopdown {
    0%   {top: 0px;-webkit-transform: rotate(0deg) scale(1);}
    50%  {top: 10px; -webkit-transform: rotate(180deg) scale(1.04);}
    100% {top: 0px;-webkit-transform: rotate(360deg) scale(1);}
}
@-webkit-keyframes mydowntop {
    0%   {top: 0px; -webkit-transform: rotate(0deg);}
    50%  {top: -10px; -webkit-transform: rotate(-180deg);}
    100% {top: 0px; -webkit-transform: rotate(-360deg);}
}
@-webkit-keyframes mytopleft {
    0%   {left: 0px;top:0px;-webkit-transform: rotate(0deg);}
    50%  {left: 15px; top:5px;-webkit-transform: rotate(180deg);}
    100% {left: 0px; top:0px;-webkit-transform: rotate(360deg);}
}
@-webkit-keyframes mydownright {
    0%   {left: 0px;top:0px;-webkit-transform: rotate(0deg);}
    50%  {left: -10px; top:-5px;-webkit-transform: rotate(180deg);}
    100% {left: 0px; top:0px;-webkit-transform: rotate(360deg);}
}
@-webkit-keyframes mytopright {
    0%   {left: 0px;top:0px;-webkit-transform: rotate(0deg);}
    50%  {left: -10px; top:5px;-webkit-transform: rotate(180deg);}
    100% {left: 0px; top:0px;-webkit-transform: rotate(360deg);}
}
@-webkit-keyframes mydownleft {
    0%   {left: 0px;top:0px;-webkit-transform: rotate(0deg);}
    50%  {left: 10px; top:10px;-webkit-transform: rotate(-180deg);}
    100% {left: 0px; top:0px;-webkit-transform: rotate(-360deg);}
}
@-webkit-keyframes myturn {
    0%   {-webkit-transform: rotate(0deg) skewX(0deg);}
    100% {-webkit-transform: rotate(-360deg) skewX(0deg);}
}
